<!--
 * @Description: 
 * @Author: xiawenlong
 * @Date: 2021-11-19 16:59:32
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-11-19 17:13:52
-->
<template>
  <div class="updateSuccess">
    <img src="@/assets/college/Accounts/pay_success_icon.png" alt="" />
    <div class="title">支付成功</div>
    <p>恭喜您，成功将书院升级为企业版</p>
    <div class="des">
      <p>企业版书院系统将会赠送你{{ avaiableNum }}门精品课程免费使用</p>
      <p>点击下方按钮领取</p>
    </div>
    <el-button @click="toAccount">领取精品课程</el-button>
  </div>
</template>
<script>
import { getGiveDetail } from '@/api/college'
import to from 'await-to'
export default {
  name: 'UpdateSuccess',
  data() {
    return {
      avaiableNum: 0,
    }
  },
  mounted() {
    this.getAccountsSeatData()
  },
  methods: {
    async getAccountsSeatData() {
      const [res, err] = await to(getGiveDetail())
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.avaiableNum = res.data.avaiableNum
    },
    toAccount() {
      this.$router.push({
        name: 'AccountsManage',
        params: {
          giveCourse: 1,
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.updateSuccess {
  height: calc(100vh - 150px);
  background-color: #fff;
  text-align: center;
  padding-top: 196px;
  box-sizing: border-box;
  img {
    display: block;
    margin: 0 auto;
    width: 46px;
    height: 46px;
    margin-bottom: 16px;
  }
  .title {
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    color: #333333;
    margin-bottom: 20px;
  }
  > p {
    line-height: 14px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    margin-bottom: 30px;
  }
  .des {
    p {
      font-size: 14px;
      line-height: 14px;
      color: #999999;
      margin-bottom: 8px;
    }
  }
  .el-button {
    width: 218px;
    height: 40px;
    background: #ff7b33;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    margin: 0 auto;
  }
}
</style>
